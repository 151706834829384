/*
 * @Autor: oops.liu
 * 商品点击事件
 */
import native from "@/utils/native.js";
import { JUMP_DETAIL } from "@/mixin/mixin_file.js";
export default {
  methods: {
    /**
     * @description: 
     * @param {*}item，商品信息
     * @return {*}
     */
    // 关注店铺
    handleGoods(item) {
      native(JUMP_DETAIL, { spuId: item.id });
    },
  },

};
