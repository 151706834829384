/*
 * @Autor: oops.liu
 * scroll事件
 */
export default {
  data() {
    return {
      pages: 1,
      size: 10,
      noDataText: "已经到底啦~",
      dataLength: 0, //数据的长度
      listData: [],
    };
  },
  methods: {
    refresh() {
      this.pages = 1;
      this.$nextTick(() => {
        this.loadInfo(true).then(() => {
          this.$refs.my_scroll.finishPullToRefresh();
        });
      });
    },
    //加载更多
    infinite() {
      if (this.dataLength < this.size) {
        this.$refs.my_scroll.finishInfinite(true);
      } else {
        this.pages++;
        this.loadInfo().then(() => {
          this.$refs.my_scroll.finishInfinite(false);
        });
      }
    },
  },
};
